<template>
  <div class="c-row ui-py-12 ui-items-center">
    <div class="ui-ml-10 rows-per-page">
      <c-dropdown
        label="Itens por página"
        :options="perPageOptions"
        :value="perPageOptions.find((element) => element.value === rowsPerPage)"
        @change="changePerPage"
        placement="left-top"
      />
    </div>
    <div class="ui-mx-auto">
      <div class="ui-flex ui-items-center pages">
        <div class="ui-mr-6">
          <c-link
            component="button"
            :disabled="prevArrowdisabled"
            @click="onPrev"
          >
            <icon name="arrow-left" :color="prevArrowColor" />
          </c-link>
        </div>
        <div class="c-text-b2 c-text-gray-300">Página</div>
        <div class="ui-mx-4">
          <input
            type="number"
            :value="page"
            class="ui-p-2 ui-w-12 ui-border c-border-gray-200 ui-rounded no-spin ui-text-center"
            min="1"
            :max="totalPages"
            @keyup.enter="onEnter"
          />
        </div>
        <div class="c-text-b2 c-text-gray-300">de {{ totalPages }}</div>

        <div class="ui-ml-6">
          <c-link
            component="button"
            :disabled="nextArrowdisabled"
            @click="onNext"
          >
            <icon name="arrow-right" :color="nextArrowColor" />
          </c-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CDropdown, CLink, Icon } from "@estrategiahq/coruja-web-ui";
export default {
  components: {
    CDropdown,
    CLink,
    Icon,
  },
  props: {
    page: Number,
    totalPages: Number,
    rowsPerPage: Number,
  },
  data() {
    return {
      perPageOptions: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      prevArrowColor: "var(--c-color-gray-300)",
      nextArrowColor: "var(--c-color-gray-300)",
      prevArrowdisabled: true,
      nextArrowdisabled: true,
    };
  },
  mount() {
    this.updateArrowsStatus(this.page);
  },
  watch: {
    page(value) {
      this.updateArrowsStatus(value,this.totalPages);
    },
    totalPages(value) {
      this.updateArrowsStatus(this.page,value);
    },
  },
  methods: {
    changePerPage(value) {
      this.$emit("changePerPage", value.value);
    },
    onEnter(event) {
      this.changePage(event?.target?.value);
    },
    changePage(value) {
      this.$emit("changePage", value);
    },
    onPrev() {
      if (this.page - 1 > 0) {
        this.$emit("changePage", this.page - 1);
      }
    },
    onNext() {
      if (this.page + 1 <= this.totalPages) {
        this.$emit("changePage", this.page + 1);
      }
    },
    updateArrowsStatus(page,totalPages) {
      if (page == 1) {
        this.prevArrowColor = "var(--c-color-gray-300)";
        this.prevArrowdisabled = true;
      } else {
        this.prevArrowColor = "var(--c-color-gray-400)";
        this.prevArrowdisabled = false;
      }
      if (page == totalPages) {
        this.nextArrowColor = "var(--c-color-gray-300)";
        this.nextArrowdisabled = true;
      } else {
        this.nextArrowColor = "var(--c-color-gray-400)";
        this.nextArrowdisabled = false;
      }
    },
  },
}
</script>
<style scoped >
.rows-per-page {
  width: 248px;
}
.pages {
  width: 320px;
  margin-left: -148px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button ,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
