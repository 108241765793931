<template>
  <div
    v-if="show"
    tabindex="99999"
    class="ui-fixed ui-z-10 ui-inset-0 ui-overflow-y-auto"
  >
    <div
      class="
        ui-flex
        ui-items-end
        ui-justify-center
        ui-min-h-screen
        ui-pt-4
        ui-px-4
        ui-pb-20
        ui-text-center
        sm:ui-block sm:ui-p-0
      "
    >
      <div
        class="
          ui-fixed
          ui-inset-0
          ui-bg-gray-500
          ui-bg-opacity-75
          ui-transition-opacity
        "
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="ui-hidden sm:ui-inline-block sm:ui-align-middle sm:ui-h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          ui-inline-block ui-align-bottom
          c-bg-white
          ui-rounded-lg
          ui-text-left
          ui-overflow-hidden
          ui-shadow-xl
          ui-transform
          ui-transition-all
          ui-border-2
          sm:ui-my-8 sm:ui-align-middle sm:ui-max-w-xl sm:ui-w-full
          c-border-gray-100
        w-modal
        "
      >
      <div class="c-row ui-p-2">
            <div class="c-col ui-text-right ">
                <c-link component="button" @click="close"><icon name="close" color="var(--c-color-gray-300)" width="24" /></c-link>
            </div>
          </div>
        <div class="c-bg-white ui-px-4 ui-pt-2 ui-pb-4 ">
          <div class="c-row">
            <div class="c-col ui-justify-center c-text-subtitle ui-text-center c-text-primary">
                <slot name="title"></slot>
            </div>
          </div>
          <div class="c-row">
            <div class="c-col ui-justify-center mt-2">
              <div class="c-text-b2 ui-text-center c-text-gray-300">
                <slot name="description"></slot>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            ui-bg-gray-50
            sm:ui-p-6
            ui-justify-items-center
  
            ui-mt-8
            shadow-top
          "
        >
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import{CLink,Icon} from '@estrategiahq/coruja-web-ui'
export default {
    components:{CLink,Icon,},
  props: ["show"],
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.w-modal{
    width: 560px;
}
.shadow-top {
    box-shadow: inset 0px 1px 0px rgba(153, 153, 153, 0.24);

}
</style>
