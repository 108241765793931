<template>
  <div class="ui-px-6">
    <div class="ui-py-8 c-text-h2 ui-text-left" data-cy="lista-de-estantes">Lista de Estantes</div>
    <div class="c-row ui-mb-8 ui-items-center">
      <div class="c-col ui-flex-grow">
        <c-search-bar
          placeholder="Busque por título da estante"
          v-model="filter"
        />
      </div>
      <div class="c-col ui-flex-grow-0">
        <c-button color="primary" class="add-button" @click="createShelf">
          Criar nova estante
          <icon name="add" class="c-icon--right" />
        </c-button>
      </div>
    </div>
    <div class="c-row">
      <div class="c-col">
        <c-table class="ui-w-full">
          <template slot="head">
            <c-table-row>
              <c-table-head> Título da Estante </c-table-head>
              <c-table-head> Criado em </c-table-head>
              <c-table-head class="no-wrap"> Atualizado em </c-table-head>
              <c-table-head align="right" class="ui-pr-2"> Ações </c-table-head>
            </c-table-row>
          </template>

          <c-table-row v-for="shelf in items" :key="shelf.id">
            <c-table-data>
              <div v-show="!loading">{{ shelf.title }}</div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
            <c-table-data>
              <div v-show="!loading">{{ shelf.created_at }}</div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
            <c-table-data>
              <div v-show="!loading">{{ shelf.updated_at }}</div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
            <c-table-data align="right">
              <div class="ui-flex ui-justify-end" v-show="!loading">
                <c-link flat color="primary" @click="editShelf(shelf.id)">
                  <icon
                    name="edit"
                    outline
                    class="c-icon--right"
                    color="primary"
                  />
                </c-link>
                <c-link flat color="red" @click="deleteShelfDialog(shelf)">
                  <icon
                    name="trash"
                    height="20px"
                    outline
                    class="c-icon--right"
                    color="red"
                  />
                </c-link>
              </div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
          </c-table-row>
        </c-table>
      </div>
    </div>
    <pagination
      :page="pagination.page"
      :total-pages="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
      :rowsPerPage="pagination.rowsPerPage"
      @changePage="changePage"
      @changePerPage="changePerPage"
    />

    <modal :show="deleteDialog" @close="deleteDialog = false">
      <template v-slot:title>Deseja remover esta estante?</template>
      <template v-slot:description>
        <p>{{ selectedShelf.title }}</p>
        <p>
          Ao remover a estante, todos os álbuns salvos nela também serão
          excluídos.
        </p>
      </template>
      <template v-slot:actions>
        <div class="ui-flex">
          <div class="ui-flex-1">
            <c-link
              class="ui-w-full ui-h-full c-text-gray-300"
              component="button"
              @click="deleteDialog = false"
              >Não, cancelar</c-link
            >
          </div>
          <div class="ui-flex-1">
            <c-button
              class="ui-w-full"
              color="primary"
              @click="deleteShelf(selectedShelf.id)"
              >Sim, remover</c-button
            >
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>


<script>
import {
  CButton,
  CSearchBar,
  Icon,
  CTable,
  CTableRow,
  CTableHead,
  CTableData,
  CLink,
  CSkeletonBlock,
} from "@estrategiahq/coruja-web-ui";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination.vue";


const loadingItems = Array.from({ length: 6 }, () => ({}));

export default {
  components: {
    CButton,
    CSearchBar,
    Icon,
    CTable,
    CTableRow,
    CTableHead,
    CTableData,
    CLink,
    CSkeletonBlock,
    Modal,
    Pagination,
  },
  data() {
    return {
      items: loadingItems,
      lastSort: "",
      filter: "",
      clickedLabel: {
        name: "",
        status: true,
      },
      deleteDialog: false,
      loading: true,
      pagination: {
        page: 1,
        prevPage: 0,
        rowsPerPage: 20,
        rowsNumber: 10,
        info: {},
      },
      timeout: null,
    };
  },
  mounted() {
    this.fetchShelves({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  watch: {
    async filter(newFilter) {
      newFilter = newFilter.trim();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () =>
          this.fetchShelves({
            pagination: this.pagination,
            filter: newFilter,
            sortBy: this.sortBy,
          }),
        300
      );
    },
  },
  methods: {
    async fetchShelves({ pagination, filter }) {
      let search = `?page=${pagination.page}&per_page=${pagination.rowsPerPage}`;
      search += !filter.length ? "" : `&search=(title[icontains]:'${filter}')`;

      let result = await this.$s.shelf.fetchShelves(search);
      this.loading = false;
      this.items = result.data.map((item) => ({
        ...item,
        created_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at)
        ),
        updated_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at) > Date.parse(item.updated_at)
            ? Date.parse(item.created_at)
            : Date.parse(item.updated_at)
        ),
      }));
      this.pagination.page = result.meta.page;
      this.pagination.rowsPerPage = result.meta.per_page;
      this.pagination.rowsNumber = result.meta.total;
    },
    createShelf() {
      this.$router.push({
        name: "createShelf",
      });
    },
    editShelf(id) {
      this.$router.push({
        name: "editShelf",
        params: { id, edit: true },
      });
    },
    deleteShelfDialog(shelf) {
      this.deleteDialog = true;
      this.selectedShelf = shelf;
    },
    async deleteShelf(id) {
      await this.$s.shelf.deleteShelf(id);
      this.$emit("toast", {
        type: "success",
        text: "Estante removida com sucesso",
        timeout: 3000,
        position: "top",
      });      
      this.loading = true;
      this.fetchShelves({
        pagination: this.pagination,
        filter: this.filter,
      });
      this.deleteDialog = false;
    },
    async changePerPage(value) {
      this.loading = true;
      this.pagination = {
        page: 1,
        prevPage: 0,
        rowsPerPage: value,
      };
      await this.fetchShelves({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
    async changePage(page) {
      this.loading = true;
      this.pagination.page = page;
      await this.fetchShelves({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
  },
};
</script>

<style scoped>
.add-button {
  width: 229px;
}
.no-wrap {
  white-space: nowrap;
}
</style>